<script setup lang="ts">
import { ElButton } from 'element-plus';

const emit = defineEmits(['done', 'failed']);
</script>

<template>
  <div>
    <p class="text-center">
      You need to be an Australian resident and 18 years or older to participate in this promotion.
    </p>

    <p class="text-center">By clicking 'YES, I CONFIRM' you acknowledge that you are.</p>

    <div class="flex flex-col items-center justify-center space-y-2">
      <el-button type="primary" @click="emit('done')">YES, I CONFIRM</el-button>
      <el-button type="primary" class="submit-btn" @click="emit('failed')">NO</el-button>
    </div>
  </div>
</template>

<style scoped></style>
