import type { MiddlewareFunction } from '@/router/middleware/index';
import type { Activity } from '@/models/activity/Activity';
import type { MaybeExternalPage, OptionalPage, Page } from '@/types/Page';
import type { RouteLocationRaw } from 'vue-router';
import { usePage } from '@/composables/usePage';

type ActivityPages = Exclude<keyof Activity['frontend']['pages'], 'global'>;

export function ensureRouteEnabled(
  page: Exclude<ActivityPages, 'global' | 'other'>,
  toRoute: RouteLocationRaw = { path: '/entry' }
): MiddlewareFunction {
  function exit(message: string) {
    console.log(message);
    return toRoute;
  }

  return () => {
    console.debug('Checking page is enabled');
    const pageObj = usePage(page);

    // Page is optional but not enabled, redirect to specified route
    if (isOptionalPage(pageObj) && !pageObj.enabled) return exit('Page is not enabled; redirecting...');

    // Page is external, redirect to specified route
    // if (isMaybeExternalPage(pageObj) && pageObj.external) return exit('Page is external; redirecting...');
  };
}

function isOptionalPage(page: Page): page is OptionalPage {
  return 'enabled' in page;
}

function isMaybeExternalPage(page: Page): page is Page & MaybeExternalPage {
  return 'external' in page;
}
