import type { MiddlewareFunction } from '@/router/middleware/index';
import { useActivity } from '@/composables/useActivity';
import { usePage } from '@/composables/usePage';
import dayjs from 'dayjs';
import type { WinnersPage } from '@/types/Page';
import type { RouteLocationPathRaw } from 'vue-router';
import { app } from '@/helpers/appEnv';
import { toast } from '@/helpers/toast';

export const timingsRedirects: MiddlewareFunction = async ({ to }) => {
  const activity = useActivity();

  const now = dayjs.utc();
  const activityStart = dayjs.utc(activity.timings.start);
  const activityEnd = dayjs.utc(activity.timings.end);

  const path = to.path.slice(1);
  const page = usePage(to);

  if (page.viewableOutsideTimings) return;

  function exit(exitRoute: RouteLocationPathRaw, message: string) {
    if (app.isLiveTest) {
      // Skip redirect
      toast.warning(`Skipped redirect to ${exitRoute.path}; site is in live test`);
      return;
    }

    console.log(`[Timings Redirect] Redirecting to '${exitRoute.path}': ${message}`);
    return exitRoute;
  }

  if (now.isBefore(activityStart)) {
    if (path === 'upcoming') return;

    return exit({ path: '/upcoming' }, "Activity hasn't started");
  } else if (path === 'winners' && canShowWinnersPage()) {
    // User is on winners page and show results date is in past
    return;
  } else if (now.isAfter(activityEnd)) {
    // End date is in past
    if (canShowWinnersPage()) {
      // TODO: check and redirect to closed page if there aren't any winners posted yet
      return exit({ path: '/winners' }, 'Activity has ended and winners have been posted');
    } else if (path !== 'closed') {
      return exit({ path: '/closed' }, 'Activity has ended');
    }
  } else {
    // TODO: implement active date / times overrides

    if (['upcoming', 'winners', 'closed', 'inactive'].includes(path)) {
      return exit({ path: '/' }, 'Activity is active');
    }
  }
};

function canShowWinnersPage() {
  const winnersPage = usePage<WinnersPage>('winners');
  const now = dayjs();

  return winnersPage.enabled && (!winnersPage.hideUntil || now.isAfter(winnersPage.hideUntil));
}
