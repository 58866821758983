import { Model } from '@/models/Model';
import { plainToInstance } from 'class-transformer';
import { Win } from '@/models/Win';

export class Entry extends Model {
  activity!: string;
  user!: string;
  rawEntry!: string;
  data!: Record<string, any>;
  wins!: Win[];
  testAlerts?: string[];
  createdAt!: Date;
  updatedAt!: Date;

  static fromApi(data: any) {
    const instance = plainToInstance(Entry, data);

    instance.wins = instance.wins.map((winData: any) => Win.fromApi(winData));

    return instance;
  }
}
