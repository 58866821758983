import { Model } from '@/models/Model';
import type { PageColors } from '@/types/PageColors';
import { FileUpload } from '@/models/FileUpload';
import { plainToInstance } from 'class-transformer';
import type { Component } from 'vue';
import { defineAsyncComponent, markRaw } from 'vue';

export enum PrizeRedemptionMethod {
  INSTRUCTIONS = 'instructions',
  VOUCHER = 'voucher',
  CASHCARD = 'cashcard',
  FORM = 'form',
  STAFF_QR = 'staffQr',
}

const redemptionComponentMap: Record<PrizeRedemptionMethod, Component> = {
  [PrizeRedemptionMethod.INSTRUCTIONS]: defineAsyncComponent(() => import('@/components/prizes/InstructionsPrize.vue')),
  [PrizeRedemptionMethod.STAFF_QR]: defineAsyncComponent(() => import('@/components/prizes/StaffQrPrize.vue')),
  [PrizeRedemptionMethod.VOUCHER]: defineAsyncComponent(() => import('@/components/prizes/VoucherPrize.vue')),
  [PrizeRedemptionMethod.CASHCARD]: defineAsyncComponent(() => import('@/components/prizes/CashcardPrize.vue')),
};

export class Prize extends Model {
  name!: string;
  redemption!: {
    method: PrizeRedemptionMethod;
    parameters: Record<string, any>;
  };
  frontend!: {
    description?: string;
    image?: FileUpload;
    banner?: FileUpload;
    claimLabel?: string;
    confirmPopup?: string;
    redeemedPopup?: string;
    overrideColors: PageColors;
  };

  static fromApi(data: any) {
    const instance = plainToInstance(Prize, data);

    if (instance.frontend.image) instance.frontend.image = FileUpload.fromApi(instance.frontend.image);
    if (instance.frontend.banner) instance.frontend.banner = FileUpload.fromApi(instance.frontend.banner);

    return instance;
  }

  get component() {
    return markRaw(redemptionComponentMap[this.redemption.method]);
  }
}
