import { Model } from '@/models/Model';
import { plainToInstance } from 'class-transformer';
import type { FileUpload } from '@/models/FileUpload';
import type { Premise } from '@/models/Premise';
import type { Brand } from '@/models/Brand';
import { Form } from '@/models/form/Form';
import type { OutputMechanic } from '@/types/OutputMechanic';
import type { InputMechanic } from '@/types/InputMechanic';
import router from '@/router';
import type { ActivityFrontendColors } from '@/models/activity/ActivityFrontendColors';
import type { ActivityFrontendPages } from '@/models/activity/ActivityFrontendPages';

type MechanicWithParams<Mechanic> = {
  mechanic: Mechanic;
  parameters: Record<string, any>;
};

export type FontDefinition = {
  light?: FileUpload;
  regular?: FileUpload;
  bold?: FileUpload;
  black?: FileUpload;

  scale: number;
};

export enum AgeGateType {
  DATE_OF_BIRTH = 'dateOfBirth',
  YES_OR_NO = 'yesOrNo',
}

export class Activity extends Model {
  title!: string;
  timings!: {
    online: Date;
    start: Date;
    end: Date;
    offline: Date;
  };
  frontend!: {
    ageGate: {
      enabled: boolean;
      ageLimit?: number;
      type?: AgeGateType;
    };
    icon: FileUpload;
    banners: {
      mobile: FileUpload;
      desktop: FileUpload;
      arrow: {
        enabled: boolean;
        color: string;
        opacity: number;
      };
    };
    audio?: FileUpload;
    colors: ActivityFrontendColors;
    fonts: {
      headings?: FontDefinition;
      body?: FontDefinition;
    };
    form: Form;
    pages: ActivityFrontendPages;
    tracking: {
      googleAnalytics?: string;
      metaPixel?: string;
    };
  };
  premises!: Premise[];
  brands!: Brand[];
  inputs!: MechanicWithParams<InputMechanic>[];
  outputs!: MechanicWithParams<OutputMechanic>[];
  participation?: {
    entries: number;
    wins: number;
  };

  static fromApi(data: any) {
    const instance = plainToInstance(Activity, data);

    instance.frontend.form = Form.fromApi(instance.frontend.form);

    instance.generateOtherRoutes();
    // TODO: preload banner images

    return instance;
  }

  generateOtherRoutes() {
    const pages = this.frontend.pages;
    const currentPageName = window.location.pathname.substring(1);
    for (const page of pages.other) {
      if (!page.enabled) continue;
      // if (page.external) continue;
      if (router.hasRoute(page.slug)) continue;

      router.addRoute({ name: page.slug, path: `/${page.slug}`, component: () => import('@/views/PageView.vue') });

      if (currentPageName === page.slug) router.replace(`/${currentPageName}`);
    }
  }
}
