// This file is adapted from prize_path_condition.ts from the api

import { Activity } from '@/models/activity/Activity';
import { useActivity } from '@/composables/useActivity';
import { useEntryStore } from '@/stores/entry';

export enum FieldDisplayConditionType {
  FORM_FIELD = 'formField',
  CHANCE = 'chance',
  LIMITS = 'limits',
  // TEMPERATURE = 'temperature',
}

export type FieldDisplayConditionConstructorParameters = {
  parameters: any;
};

export interface FieldDisplayConditionDocument<ParametersType = {}> {
  type: FieldDisplayConditionType;
  parameters: ParametersType;
}

export abstract class FieldDisplayCondition<ParametersType = {}>
  implements FieldDisplayConditionDocument<ParametersType>
{
  abstract type: FieldDisplayConditionType;
  parameters: ParametersType;

  protected activity: Activity;

  constructor(parameters: FieldDisplayConditionConstructorParameters) {
    this.activity = useActivity();
    this.parameters = parameters.parameters;
  }

  get entryData() {
    return useEntryStore().rawEntry;
  }

  abstract solve(): boolean;
}
