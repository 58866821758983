import { ensureRouteEnabled } from '@/router/middleware/pageEnabled';
import EntryView from '@/views/EntryView.vue';

export const routes = [
  {
    name: 'landing',
    path: '/',
    component: () => import('@/views/LandingView.vue'),
    meta: { middleware: [ensureRouteEnabled('landing')] },
  },
  {
    name: 'entry',
    path: '/entry',
    component: EntryView,
  },
  {
    name: 'result',
    path: '/result',
    component: () => import('@/views/ResultView.vue'),
  },
  {
    name: 'game',
    path: '/game',
    component: () => import('@/views/GameView.vue'),
    meta: { middleware: [ensureRouteEnabled('game', { path: '/result' })] },
  },
  {
    name: 'terms',
    path: '/terms',
    component: () => import('@/views/PageView.vue'),
    meta: { middleware: [ensureRouteEnabled('terms')] },
  },
  {
    name: 'privacy',
    path: '/privacy',
    component: () => import('@/views/PageView.vue'),
    meta: { middleware: [ensureRouteEnabled('privacy')] },
  },
  {
    name: 'contact',
    path: '/contact',
    component: () => import('@/views/ContactView.vue'),
    meta: { middleware: [ensureRouteEnabled('contact')] },
  },
  {
    name: 'premise-locator',
    path: '/premise-locator',
    component: () => import('@/views/PremiseLocatorView.vue'),
    meta: { middleware: [ensureRouteEnabled('premiseLocator')] },
  },
  {
    name: 'refer',
    path: '/refer',
    component: () => import('@/views/ReferView.vue'),
    meta: { middleware: [ensureRouteEnabled('refer')] },
  },
  {
    name: 'upcoming',
    path: '/upcoming',
    component: () => import('@/views/PageView.vue'),
  },
  {
    name: 'closed',
    path: '/closed',
    component: () => import('@/views/PageView.vue'),
  },
];
