import { ElNotification, type NotificationHandle } from 'element-plus';

type NotificationFn = (message: string, title?: string) => NotificationHandle;

export const toast: Record<'error' | 'info' | 'success' | 'warning', NotificationFn> = {
  error: (message, title = 'Error') =>
    ElNotification.error({
      message: message,
      title: title,
    }),
  info: (message, title = 'Info') =>
    ElNotification.info({
      message: message,
      title: title,
    }),
  success: (message, title = 'Success') =>
    ElNotification.success({
      message: message,
      title: title,
    }),
  warning: (message, title = 'Warning') =>
    ElNotification.warning({
      message: message,
      title: title,
    }),
};
