<script setup lang="ts">
import { SpeakerWaveIcon, SpeakerXMarkIcon } from '@heroicons/vue/24/solid';
import { useActivity } from '@/composables/useActivity';
import { computed, ref } from 'vue';

const activity = computed(() => useActivity());

const isPlaying = ref(false);
const audioPlayer = ref<HTMLAudioElement>();

function play() {
  audioPlayer.value!.play();

  isPlaying.value = true;
}

function pause() {
  audioPlayer.value!.pause();

  isPlaying.value = false;
}
</script>

<template>
  <div v-if="activity?.frontend.audio">
    <div class="fixed right-3 top-3 z-50 text-white drop-shadow-lg">
      <SpeakerWaveIcon v-if="isPlaying" class="size-12" @click="pause()" />
      <SpeakerXMarkIcon v-else class="size-12" @click="play()" />
    </div>

    <audio ref="audioPlayer" loop>
      <source :src="activity.frontend.audio.url" />
    </audio>
  </div>
</template>

<style scoped></style>
