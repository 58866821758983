import dayjs, { Dayjs } from 'dayjs';
import { useActivity } from '@/composables/useActivity';

export function dateValidator(rule: any, value: Dayjs, callback: (e?: Error) => void) {
  if (!value.isValid()) return callback(new Error('Please enter a valid date'));

  callback();
}

export function validateDateOfBirth(dob: Dayjs) {
  const activity = useActivity();

  // If age gate isn't enabled, skip validation
  if (!activity.frontend.ageGate.enabled) return;

  const age = dayjs().diff(dob, 'years');
  if (age < activity.frontend.ageGate.ageLimit!)
    throw new Error(`You must be ${activity.frontend.ageGate.ageLimit} years old to enter this promotion`);
}

export function dateOfBirthValidator(rule: any, value: Dayjs, callback: (e?: Error) => void) {
  try {
    validateDateOfBirth(value);
    callback();
  } catch (error) {
    callback(error as Error);
  }
}
