import type { Logic } from '@/types/Logic';
import { solveLogic } from '@/types/Logic';
import FormFieldDisplayCondition from '@/types/FieldDisplayConditions/FormFieldDisplayCondition';
import type { FieldDisplayConditionDocument } from '@/types/FieldDisplayConditions/FieldDisplayCondition';
import { FieldDisplayCondition, FieldDisplayConditionType } from '@/types/FieldDisplayConditions/FieldDisplayCondition';

export function solveFieldDisplayConditions(conditions: Logic<FieldDisplayConditionDocument>) {
  return solveLogic(conditions, (condition) => {
    const instance = makeFieldDisplayCondition(condition.type, {
      parameters: condition.parameters,
    });
    return instance.solve();
  });
}

const fieldDisplayConditionMap: Record<FieldDisplayConditionType, (parameters: any) => FieldDisplayCondition> = {
  [FieldDisplayConditionType.FORM_FIELD]: (parameters: any) => new FormFieldDisplayCondition(parameters),
  // [FieldDisplayConditionType.CHANCE]: () => import('#types/prize_path_conditions/chance_prize_path_condition'),
  // [FieldDisplayConditionType.LIMITS]: () => import('#types/prize_path_conditions/limits_prize_path_condition'),
};

function makeFieldDisplayCondition(type: FieldDisplayConditionType, parameters: any) {
  if (!Object.keys(fieldDisplayConditionMap).includes(type))
    throw new Error(`Tried to make undefined field display condition '${type}'`);

  return fieldDisplayConditionMap[type](parameters);
}
