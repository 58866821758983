<script setup lang="ts">
import { onMounted, reactive, ref, nextTick } from 'vue';
import { type FormInstance, type InputInstance } from 'element-plus';
import { useEntryStore } from '@/stores/entry';
import dayjs from 'dayjs';
import { validateDateOfBirth } from '@/validators/dateValidator';
import { useActivity } from '@/composables/useActivity';

const props = defineProps<{
  ageGateVisible: boolean;
}>();

const emit = defineEmits<{ done: [] }>();

const entryStore = useEntryStore();
const activity = useActivity();

const ageGateForm = ref<FormInstance>();
const form = reactive({
  day: '',
  month: '',
  year: '',
});
const day = ref<InputInstance>();
const month = ref<InputInstance>();
const year = ref<InputInstance>();
const inputRefs = { day, month, year };
const error = ref<string | null>(null);

// If age gate is visible, focus the first input
onMounted(() => {
  if (props.ageGateVisible) nextTick(() => nextTick(() => day.value!.focus()));
});

function onInput(nextName: keyof typeof inputRefs, value: string) {
  if (value.length >= 2 && /^\d*$/.test(value)) nextTick(() => inputRefs[nextName].value!.focus());
}

async function submit() {
  error.value = '';
  await ageGateForm.value!.validate();

  const { year, month, day } = form;

  const dayjsDob = dayjs(day.toString() + month.toString() + year.toString(), 'DDMMYYYY', true);
  if (!dayjsDob.isValid()) {
    error.value = 'The date you entered is invalid.';
    return;
  }

  try {
    validateDateOfBirth(dayjsDob);
    entryStore.ageGateDateOfBirth = dayjsDob.format('YYYY-MM-DD');
    emit('done');
  } catch (err) {
    error.value = `You must be ${activity.frontend.ageGate.ageLimit!} years or over to continue`;
    return;
  }
}

const ageGateRules = {
  day: [
    { required: true, message: ' ', trigger: 'blur' },
    { pattern: /[0-9]{2}$/g, message: ' ', trigger: 'blur' },
  ],
  month: [
    { required: true, message: ' ', trigger: 'blur' },
    { pattern: /[0-9]{2}$/g, message: ' ', trigger: 'blur' },
  ],
  year: [
    { required: true, message: ' ', trigger: 'blur' },
    { pattern: /[0-9]{4}$/g, message: ' ', trigger: 'blur' },
  ],
};
</script>

<template>
  <div>
    <h2 class="text-center text-lg text-primary font-bold mb-4">
      You must be {{ activity.frontend.ageGate.ageLimit! }} years or over to continue.
    </h2>
    <p class="text-center mb-2">Please enter your date of birth:</p>

    <el-form ref="ageGateForm" :model="form" :rules="ageGateRules" class="age-gate-form">
      <el-row :gutter="10" justify="center" type="flex">
        <el-col :span="6" :sm="5" :md="4">
          <el-form-item required prop="day">
            <el-input
              ref="day"
              v-model="form.day"
              :maxlength="2"
              placeholder="DD"
              inputmode="numeric"
              @input="onInput('month', $event)"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6" :sm="5" :md="4">
          <el-form-item required prop="month">
            <el-input
              ref="month"
              v-model="form.month"
              :maxlength="2"
              placeholder="MM"
              inputmode="numeric"
              @input="onInput('year', $event)"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7" :sm="6" :md="5">
          <el-form-item required prop="year">
            <el-input
              ref="year"
              v-model="form.year"
              :maxlength="4"
              placeholder="YYYY"
              inputmode="numeric"
              @keydown.enter="submit"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-alert v-if="error" class="!my-2" effect="dark" :closable="false" type="error">{{ error }}</el-alert>

          <div class="text-center">
            <el-button type="primary" class="submit-btn" @click="submit">ENTER</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style>
/*noinspection CssUnusedSymbol*/
.age-gate-form .el-input__inner {
  @apply text-center;
}
</style>
