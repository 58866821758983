import { get } from 'lodash-es';
import { FieldDisplayCondition, FieldDisplayConditionType } from '@/types/FieldDisplayConditions/FieldDisplayCondition';
import { FieldType, FormField } from '@/models/form/FormField';

export enum FormFieldOperator {
  IS_SET = 'exists',
  IS_NOT_SET = 'notExists',
  EQUALS = 'eq',
  DOES_NOT_EQUAL = 'ne',
  GREATER_THAN = 'gt',
  GREATER_THAN_OR_EQUAL = 'gte',
  LESS_THAN = 'lt',
  LESS_THAN_OR_EQUAL = 'lte',
  IS_IN = 'in',
  IS_NOT_IN = 'nin',
}

type ConditionCheckerParams = { data: any; target: any; field: FormField };

type ConditionChecker = (params: ConditionCheckerParams) => boolean;

const conditionsMap: Record<FormFieldOperator, ConditionChecker> = {
  [FormFieldOperator.IS_SET]: ({ data }) => data !== undefined,
  [FormFieldOperator.IS_NOT_SET]: ({ data }) => data === undefined,
  [FormFieldOperator.EQUALS]: ({ data, target }) => data === target,
  [FormFieldOperator.DOES_NOT_EQUAL]: ({ data, target }) => data !== target,
  [FormFieldOperator.GREATER_THAN]: ({ data, target }) => data > target,
  [FormFieldOperator.GREATER_THAN_OR_EQUAL]: ({ data, target }) => data >= target,
  [FormFieldOperator.LESS_THAN]: ({ data, target }) => data < target,
  [FormFieldOperator.LESS_THAN_OR_EQUAL]: ({ data, target }) => data <= target,
  [FormFieldOperator.IS_IN]: ({ data, target }) => (target as any[]).includes(data),
  [FormFieldOperator.IS_NOT_IN]: ({ data, target }) => !(target as any[]).includes(data),
};

export default class FormFieldDisplayCondition extends FieldDisplayCondition<ParametersType> {
  type = FieldDisplayConditionType.FORM_FIELD;

  solve(): boolean {
    // Load field
    const field = this.activity.frontend.form.findFieldByPath(this.parameters.path);
    if (!field) throw new Error(`Field with path '${this.parameters.path}' not found.`);

    // Load data from entry
    let data: any = get(this.entryData, this.parameters.path);
    let target = this.parameters.target;

    // Convert dates to timestamps
    if (field.type === FieldType.DATE) {
      data = data !== undefined ? (data as Date).getTime() : undefined;

      // Handle target being array or undefined
      target = Array.isArray(target)
        ? target.map((targetItem) => (targetItem as Date).getTime())
        : target !== undefined
          ? (target as Date).getTime()
          : undefined;
    }

    // Run condition checking
    return conditionsMap[this.parameters.operator]({ data, target, field });
  }
}

type ParametersType = {
  path: string;
  operator: FormFieldOperator;
  target?: any;
};
