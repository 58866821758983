import Cookies from 'js-cookie';
import type { StorageLike } from 'pinia-plugin-persistedstate';

export class CookieStorage implements StorageLike {
  constructor(protected setCookieOptions?: Cookies.CookieAttributes) {}

  getItem(key: string): string | null {
    return Cookies.get(key) ?? null;
  }

  setItem(key: string, value: string): void {
    Cookies.set(key, value, this.setCookieOptions);
  }
}
