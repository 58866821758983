import tinycolor from 'tinycolor2';

function LightenDarkenColor(col: string, amt: number) {
  const usePound = col[0] == '#';

  const color = tinycolor(col).lighten(amt);
  return usePound ? color.toHexString() : color.toHex();
}

function hexHalfToFull(half: string) {
  half = half.substring(1);
  return (
    '#' +
    (half.length === 3
      ? half
          .split('')
          .map((h) => h + h)
          .join('')
      : half)
  );
}

export { LightenDarkenColor, hexHalfToFull };
