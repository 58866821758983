<script setup lang="ts">
import { useActivity } from '@/composables/useActivity';

const activity = useActivity();
const arrow = activity.frontend.banners.arrow;

function scrollTo() {
  const el = document.getElementById('main');
  el?.scrollIntoView({ behavior: 'smooth' });
}
</script>

<template>
  <a v-if="arrow.enabled" class="arrow" href="#" @click.prevent="scrollTo">
    <span />
  </a>
</template>

<style scoped>
.arrow {
  @apply inline-block absolute bottom-0 left-0 w-full transition-opacity duration-300;
  padding-top: 70px;
  z-index: 2;
  color: #000;
  font:
    normal 400 20px/1 'Josefin Sans',
    sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
}
.arrow span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  border-color: v-bind('arrow.color');
  transform: rotate(-45deg);
  animation: pulse-down 1.5s infinite;
  box-sizing: border-box;
}

@keyframes pulse-down {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
</style>
