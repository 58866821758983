import { plainToInstance } from 'class-transformer';
import { FormStep } from '@/models/form/FormStep';
import type { FormField, FormStages } from '@/models/form/FormField';
import type { FormItemRule } from 'element-plus';
import type { RawEntry } from '@/types/RawEntry';
import { get, set } from 'lodash-es';

export class Form {
  steps!: FormStep[];
  userDataPaths?: {
    title?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    mobile?: string;
    dateOfBirth?: string;
  };
  rules?: Record<string, FormItemRule[]>;

  static fromApi(data: any) {
    const instance = plainToInstance(Form, data);

    instance.steps = instance.steps.map((rawStep) => FormStep.fromApi(rawStep));

    return instance;
  }

  step(step: number) {
    return this.steps[step];
  }

  get isMultiStep() {
    return this.steps.length > 1;
  }

  get fields(): FormField[] {
    return this.allFields.filter((field) => field.shouldDisplay());
  }

  get allFields(): FormField[] {
    return this.steps.flatMap((step) => step.fields);
  }

  findFieldByPath(path: string) {
    return this.allFields.find((field) => field.path === path);
  }

  runValidations = async () => Promise.all(this.fields.map(async (field) => field.runValidations()));

  async runStage(stage: keyof FormStages, data: RawEntry) {
    await Promise.all(
      this.fields.map(async (field) => {
        let fieldData = get(data, field.path);
        fieldData = await field.runStage(stage, fieldData);
        set(data, field.path, fieldData);
      })
    );

    return data;
  }

  get activeRules() {
    if (!this.rules) return {};

    return Object.fromEntries(
      Object.entries(this.rules).filter(([path, _rules]) => {
        return this.fields.some((field) => field.path === path);
      })
    );
  }

  buildRules() {
    this.rules = {};

    for (const field of this.allFields) {
      if (this.rules[field.path]) continue;

      const fieldRules: FormItemRule[] = [];

      if (field.required)
        fieldRules.push({ required: true, message: 'This field is required', trigger: ['blur', 'change'] });

      this.rules[field.path] = fieldRules;
    }
  }

  addRule(path: string, rule: FormItemRule) {
    const result = this.rules?.[path]?.push(rule);
    if (result === undefined) throw new Error("Can't add a new rule before they have been built");
  }
}
