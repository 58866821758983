/**
 * Converts camelCaseStrings to kebab-case-strings
 *
 * @param camelString
 */
export function camelToKebab(camelString: string) {
  return camelString.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase());
}

/**
 * Converts kebab-case-strings to camelCaseStrings
 *
 * @param kebabString
 */
export function kebabToCamel(kebabString: string) {
  return kebabString.replace(/-./g, (m) => m[1].toUpperCase());
}

/**
 * Uppercase the first letter of the given string. Leaves the rest of the string unchanged.
 * @param str
 */
export function ucfirst(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
