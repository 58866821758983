import type { NavigationGuard, RouteLocationNormalized, Router } from 'vue-router';
import { ensureActivityLoaded } from '@/router/middleware/ensureActivityLoaded';
import { setPageTitle } from '@/router/middleware/setPageTitle';
import { timingsRedirects } from '@/router/middleware/timingsRedirects';

export type MiddlewareFunction = (ctx: MiddlewareContext) => ReturnType<NavigationGuard>;

const globalMiddleware: MiddlewareFunction[] = [ensureActivityLoaded, timingsRedirects, setPageTitle];

export const runMiddleware: MiddlewareFunction = async (ctx) => {
  const routeMiddleware = Array.isArray(ctx.to.meta.middleware) ? ctx.to.meta.middleware : [ctx.to.meta.middleware];
  const allMiddleware: MiddlewareFunction[] = [...globalMiddleware, ...routeMiddleware].filter(
    (middleware) => middleware !== undefined
  );

  console.debug(`Running middleware for path '${ctx.to.path}'`);

  for (const middleware of allMiddleware) {
    const result = await middleware(ctx);
    if (!(result === undefined || result === true)) return result;
  }
};

export interface MiddlewareContext {
  from: RouteLocationNormalized;
  to: RouteLocationNormalized;
  router: Router;
}
