import { myyKy } from '@/api/myy';
import { User } from '@/models/User';
import type { AuthToken } from '@/types/AuthToken';

export type UserData = {
  mobile?: string;
  email?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
};

export type AuthData = { token: string; otp: string };

export const auth = {
  async view() {
    return User.fromApi(await myyKy.get(`user`).json<User>());
  },

  async authenticateTemp(data: UserData) {
    const { user, token } = await myyKy
      .post('authenticate-temp', { json: data })
      .json<{ user: User; token: AuthToken }>();

    return {
      user: User.fromApi(user),
      token,
    };
  },

  async startAuthenticate(data: { mobile: string }) {
    return myyKy.post('authenticate', { json: data }).json<{ token: string; otp?: string }>();
  },

  async verifyOtp(data: AuthData) {
    const { user, token } = await myyKy.post('otp', { json: data }).json<{ user: User; token: AuthToken }>();

    return {
      user: User.fromApi(user),
      token,
    };
  },

  async updateProfile(data: UserData) {
    return myyKy.put('user', { json: data }).json<User>();
  },
};
