import { createRouter, createWebHistory } from 'vue-router';
import { routes } from '@/router/routes';
import { runMiddleware } from '@/router/middleware';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  return runMiddleware({ to, from, router });
});

router.afterEach(() => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  if (window.top) {
    window.top.scrollTo(0, 0);
  }
  window.parentIFrame?.size();
});

export default router;
