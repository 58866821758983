import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useActivity } from '@/composables/useActivity';
import type { PageColors } from '@/types/PageColors';
import { Prize } from '@/models/Prize';
import { usePage } from '@/composables/usePage';
import { LightenDarkenColor } from '@/helpers/colorHelpers';

const defaultTheme: PageColors = {
  primary: '#2BD3FD',
  secondary: '#222222',
  tertiary: '#F8F8F8',
  pageText: '#222222',
  textOnPrimary: '#FFFFFF',
  textOnSecondary: '#FFFFFF',
  textOnTertiary: '#222222',
  pageBackground: '#F7F7F7',
  textHighlight: '#F2C55C',
};

type Shades = {
  primaryLight: string;
  primaryDark: string;
  primaryDarker: string;
};

export const useThemeStore = defineStore('theme', () => {
  const prize = ref<Prize | null>(null);
  const theme = computed<PageColors & Shades>(() => {
    const activity = useActivity();
    if (!activity) return {};

    const page = computed(() => usePage());

    const builtTheme: PageColors = {
      ...defaultTheme,
      ...activity?.frontend.colors.global,
      // @ts-ignore
      ...page.value?.overrideColors,
      ...prize.value?.frontend.overrideColors,
    };

    return {
      ...builtTheme,
      primaryLight: LightenDarkenColor(builtTheme.primary!, 6),
      primaryDark: LightenDarkenColor(builtTheme.primary!, -6),
      primaryDarker: LightenDarkenColor(builtTheme.primary!, -12),
    };
  });

  return { prize, theme };
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useThemeStore, import.meta.hot));
