import { plainToInstance } from 'class-transformer';
import { Model } from '@/models/Model';

export class FileUpload extends Model {
  url!: string;
  type!: string;

  static fromApi(data: any) {
    return plainToInstance(FileUpload, data);
  }
}
