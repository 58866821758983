import type { Win } from '@/models/Win';
import type { Entry } from '@/models/Entry';
import type { Activity } from '@/models/activity/Activity';
import type { User } from '@/models/User';
import type { Prize } from '@/models/Prize';
import { get } from 'lodash-es';
import { useActivity } from '@/composables/useActivity';
import { useAuthStore } from '@/stores/auth';
import { useEntryStore } from '@/stores/entry';

export type ParseParameters = {
  activity?: Activity;
  entry?: Entry;
  win?: Win;
  prize?: Prize;
  user?: User;
};

export function parse(
  input: string | undefined,
  parameters: Omit<ParseParameters, 'activity' | 'user' | 'entry'> = {}
) {
  // If no variables are in the input
  if (!input) return '';
  if (!input.includes('{{')) return input;

  const chunks = input.split('{{');

  const allParameters: ParseParameters = Object.assign({}, parameters, {
    activity: useActivity(),
    user: useAuthStore().user ?? undefined,
    entry: useEntryStore().entry ?? undefined,
  });

  // start at second chunk - if '{{' appears at start of string there will be an empty chunk,
  // if not then we should skip the first anyway
  let output = chunks[0];
  for (let i = 1; i < chunks.length; i++) {
    const subChunks = chunks[i].split('}}');
    const varPath = subChunks[0].trim();

    // TODO: support pipelines e.g. Date formatting: {{ activity.timings.start | formatDate('DD/MM/YYYY') }}
    output += get(allParameters, varPath) ?? '{{ ' + varPath + ' }}';
    output += subChunks[1];
  }

  return output;
}
