import { FormField } from '@/models/form/FormField';
import { plainToInstance } from 'class-transformer';

type FormStepButton = {
  label?: string;
};

type StepPopup = {
  heading?: string;
  main?: string;
};

export class FormStep {
  fields: FormField[] = [];
  heading?: string;
  enterPopup?: StepPopup;
  exitPopup?: StepPopup;
  buttons?: {
    previous?: FormStepButton;
    next?: FormStepButton;
  };
  fullscreenField!: boolean;

  get visibleFields() {
    return this.fields.filter((field) => field.shouldDisplay());
  }

  static fromApi(data: any) {
    const instance = plainToInstance(FormStep, data);

    instance.fields = instance.fields.map((field) => FormField.fromApi(field));

    return instance;
  }
}
