import type { RequireOnlyOneKey } from '@/types/RequireOnlyOneKey';

export type Logic<Target> = RequireOnlyOneKey<{
  $and?: (Logic<Target> | Target)[];
  $or?: (Logic<Target> | Target)[];
}>;

function isLogic(target: any): target is Logic<any> {
  return target.$and || target.$or;
}

export function solveLogic<Target>(logic: Logic<Target>, solver: (target: Target) => boolean): boolean {
  // Handle AND logic
  if (logic.$and && logic.$and.length > 0) {
    const tasks: boolean[] = logic.$and.map((target) => {
      return isLogic(target) ? solveLogic(target, solver) : solver(target);
    });

    return tasks.every((task) => task);
  }

  if (logic.$or && logic.$or.length > 0) {
    const tasks: boolean[] = logic.$or.map((target) => {
      return isLogic(target) ? solveLogic(target, solver) : solver(target);
    });

    return tasks.some((task) => task);
  }

  // Empty logic object should return true
  return true;
}
