import { type RouteLocationNormalized, useRoute } from 'vue-router';
import { useActivity } from '@/composables/useActivity';
import type { Activity } from '@/models/activity/Activity';
import type { Page } from '@/types/Page';
import { kebabToCamel } from '@/helpers/stringHelpers';

export type ActivityPages = Activity['frontend']['pages'];

export function usePage<PageType = Page>(target?: string | RouteLocationNormalized) {
  const route = target && isRoute(target) ? target : useRoute();
  const pageName =
    typeof target === 'string' ? target : kebabToCamel(route?.name?.toString() || route?.path.slice(1) || 'global');

  return (useActivity().frontend.pages[pageName as keyof ActivityPages] ??
    useActivity().frontend.pages.other.find((page) => page.slug === pageName)) as PageType;
}

function isRoute(route: any): route is RouteLocationNormalized {
  return !!route.path;
}
