import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { myyApi } from '@/api/myy';
import { Activity } from '@/models/activity/Activity';
import { toast } from '@/helpers/toast';

export const useAppStore = defineStore('app', () => {
  const activity = ref<Activity | null>(null);
  const loading = ref(true);

  async function fetchActivity() {
    loading.value = true;

    try {
      activity.value = await myyApi.activities.view(import.meta.env.VITE_MYY_ACTIVITY_ID);
    } catch (error) {
      toast.error('Failed to load this promotion. Please refresh the page.');
      throw error;
    }

    loading.value = false;
    return activity.value;
  }

  // hydrate(storeState: UnwrapRef<State>, initialState: UnwrapRef<State>) {
  //   storeState = initialState;
  //
  //   if (initialState.activity) storeState.activity = Activity.fromApi(initialState.activity);
  // },

  return { activity, loading, fetchActivity };
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
