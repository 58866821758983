import { Model } from '@/models/Model';
import { plainToInstance } from 'class-transformer';

// Longitude, latitude
export type Coordinates = [number, number];

export class Premise extends Model {
  name!: string;
  groups!: string[];
  country!: string;
  timezone!: string;
  address!: {
    formattedAddress?: string;
    street_line_1?: string;
    street_line_2?: string;
    country?: string;
    state?: string;
    postcode?: string;
  };
  location!: {
    type: string;
    coordinates: Coordinates;
  };
  alias!: string;

  static fromApi(data: any) {
    return plainToInstance(Premise, data);
  }
}
