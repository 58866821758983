import ky from 'ky';
import { AppEnv, app } from '@/helpers/appEnv';
import { useAuthStore } from '@/stores/auth';

const baseUrls: Record<AppEnv, string> = {
  [AppEnv.LOCAL]: 'http://localhost:3333/v1',
  [AppEnv.STAGING]: 'https://stage.platform.myyapp.com.au/v1',
  [AppEnv.LIVE_TEST]: 'https://platform.myyapp.com.au/v1',
  [AppEnv.PRODUCTION]: 'https://platform.myyapp.com.au/v1',
};

// Add auth token if it's set
const addAuthTokenToRequest = (request: Request) => {
  const token = useAuthStore().token;
  if (token) request.headers.set('Authorization', `Bearer ${token.token}`);
};

const myyKy = ky.create({
  prefixUrl: baseUrls[app.appEnv],
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Myy-Scope': 'web',
    'Myy-Platform': import.meta.env.VITE_MYY_PLATFORM,
    'Myy-Test-Mode': app.isLiveTest.toString(),
  },
  hooks: { beforeRequest: [addAuthTokenToRequest] },
  timeout: 20000,
});

const myyUploadKy = myyKy.extend({
  headers: {
    'Content-Type': undefined,
  },
});

export { myyKy, myyUploadKy };

import { activities } from '@/api/myy/modules/activities';
import { entries } from '@/api/myy/modules/entries';
import { auth } from '@/api/myy/modules/auth';
import { premises } from '@/api/myy/modules/premises';
import { refer } from '@/api/myy/modules/refer';

export const myyApi = { activities, entries, auth, premises, refer };

export type TooManyRequestsResponse = {
  errors: { message: string; retryAfter: number }[];
};
