<script setup lang="ts">
import { ref } from 'vue';
import { useActivity } from '@/composables/useActivity';
import { useRoute } from 'vue-router';
import AgeGateDob from '@/components/ageGate/AgeGateDob.vue';
import { storeToRefs } from 'pinia';
import { useEntryStore } from '@/stores/entry';
import AgeGateYesOrNo from '@/components/ageGate/AgeGateYesOrNo.vue';
import { AgeGateType } from '@/models/activity/Activity';

const ageGateVisible = ref(true);
const failed = ref(false);

const activity = useActivity();
const { ageGateDateOfBirth } = storeToRefs(useEntryStore());
const route = useRoute();

ageGateVisible.value =
  activity.frontend.ageGate.enabled && !(ageGateDateOfBirth.value || route.query.agegate === 'false');

const ageGateType = activity.frontend.ageGate.type ?? AgeGateType.DATE_OF_BIRTH;
// const failedRedirect = activity.activity_details.agegate_failed_redirect;

function done() {
  ageGateVisible.value = false;
}
</script>

<template>
  <div class="age-gate">
    <el-dialog
      id="main"
      v-model="ageGateVisible"
      title=""
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <el-row justify="center">
        <el-col :span="24">
          <div v-if="!failed">
            <AgeGateDob
              v-if="ageGateType === AgeGateType.DATE_OF_BIRTH"
              :age-gate-visible="ageGateVisible"
              @done="done"
            />
            <AgeGateYesOrNo v-else-if="ageGateType === AgeGateType.YES_OR_NO" @done="done" @failed="failed = true" />
          </div>

          <div v-else class="flex flex-col items-center justify-center">
            <p class="text-center max-w-[200px]">
              You must be an Australian resident and 18 years of age or over to enter.
            </p>
            <!--            <NuxtLink v-if="failedRedirect" :to="failedRedirect">
              <el-button type="primary">OK</el-button>
            </NuxtLink>-->
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<style></style>
