import type { MiddlewareFunction } from '@/router/middleware/index';
import { useActivity } from '@/composables/useActivity';
import { usePage } from '@/composables/usePage';

export const setPageTitle: MiddlewareFunction = async ({ to }) => {
  const activity = useActivity();
  const pageTitle = usePage(to).title;
  const siteTitle = activity.frontend.pages.global.title;
  document.title = pageTitle ? `${pageTitle} - ${siteTitle}` : siteTitle;
};
