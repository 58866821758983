<script setup lang="ts">
import { type ActivityPages, usePage } from '@/composables/usePage';
import type { MaybeExternalPage, OptionalPage } from '@/types/Page';
import { camelToKebab } from '@/helpers/stringHelpers';

const props = defineProps<{
  pageName: keyof ActivityPages;
  overrideLabel?: string;
}>();

const page = usePage<Partial<OptionalPage> & Partial<MaybeExternalPage>>(props.pageName);

// Can be undefined, so check it does not strict equal false
const enabled = page && page.enabled !== false;

const routeName = camelToKebab(props.pageName);

const label = props.overrideLabel ?? page?.linkLabel;
</script>

<template>
  <span v-if="enabled">
    <a v-if="page.external" :href="page.href" :target="page.target">{{ label }}</a>

    <RouterLink v-else :to="{ name: routeName }" :target="page.target">{{ label }}</RouterLink>
  </span>
</template>

<style lang="scss"></style>
