export enum AppEnv {
  LOCAL = 'local',
  STAGING = 'staging',
  LIVE_TEST = 'live_test',
  PRODUCTION = 'production',
}

const appEnv: AppEnv = import.meta.env.VITE_MYY_ENV || AppEnv.LOCAL;
const isLocal = appEnv === AppEnv.LOCAL;
const isStaging = appEnv === AppEnv.STAGING;
const isLiveTest = appEnv === AppEnv.LIVE_TEST;
const isProduction = appEnv === AppEnv.PRODUCTION;

export const app = { appEnv, isLocal, isStaging, isLiveTest, isProduction };
