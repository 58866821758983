<script setup lang="ts">
import { useActivity } from '@/composables/useActivity';
import BannerArrow from '@/components/layout/BannerArrow.vue';
import BannerMedia from '@/components/layout/BannerMedia.vue';

const activity = useActivity();

const banners = activity.frontend.banners;
</script>

<template>
  <header>
    <div class="scroll" style="position: relative">
      <div>
        <RouterLink :to="{ path: '/' }">
          <BannerMedia :banner="banners.desktop" class="w-full hidden sm:block" alt="banner" />
          <BannerMedia :banner="banners.mobile" class="w-full block sm:hidden" alt="banner" />
        </RouterLink>
      </div>

      <BannerArrow />
    </div>
  </header>
</template>

<style scoped lang="scss">
.scene-container {
  position: absolute;
  bottom: 8%;
  max-width: 80%;

  img.layer {
    max-width: 100%;
  }

  .layer-bottle {
    bottom: 40px;
  }
}

.logo-container {
  padding: 10px 10px 0 10px;
}

.img-logo {
  max-width: 60px;
  display: block;
}
</style>
