import type { OutputMechanic } from '@/types/OutputMechanic';
import { plainToInstance } from 'class-transformer';
import { Prize } from '@/models/Prize';

export class Win<RedemptionType = any> {
  _id!: string;
  isWinner!: boolean;
  mechanic!: OutputMechanic;
  prize?: Prize;
  redemption?: RedemptionType;
  path!: string;
  status!: string;
  createdAt!: Date;
  updatedAt!: Date;

  static fromApi(data: any) {
    const instance = plainToInstance(Win, data);

    if (instance.prize) instance.prize = Prize.fromApi(instance.prize);

    return instance;
  }
}
