import { myyKy } from '@/api/myy';
import { Activity } from '@/models/activity/Activity';
import { type Coordinates, Premise } from '@/models/Premise';
import type { PaginatedData } from '@/api/myy/types';

export const premises = {
  async view(id: string) {
    return Premise.fromApi(await myyKy.get(`premises/${id}`).json());
  },

  async listAll(coordinates: Coordinates) {
    const response = await myyKy
      .get(`premises`, {
        searchParams: {
          lat: coordinates[1],
          lng: coordinates[0],
          activityId: import.meta.env.VITE_MYY_ACTIVITY_ID,
        },
      })
      .json<PaginatedData<Premise>>();

    return response.data.map((premiseData) => Premise.fromApi(premiseData));
  },
};
