import { myyKy } from '@/api/myy';
import { useActivity } from '@/composables/useActivity';

interface Referral {
  email: string;
}

export interface ReferData {
  referrerName: string;
  referrals: Referral[];
}

export const refer = {
  async store(data: ReferData) {
    return myyKy
      .post('refer', {
        json: {
          ...data,
          activity: useActivity().id,
        },
      })
      .json<any>();
  },
};
