import './assets/styles/main.css';
import 'element-plus/theme-chalk/el-notification.css';

import '@/helpers/dayjs';
import '@iframe-resizer/child';

import { app as appEnv } from '@/helpers/appEnv';

import { createApp } from 'vue';
import router from './router';
import App from './App.vue';
const app = createApp(App);

// Set up Sentry
import * as Sentry from '@sentry/vue';
Sentry.init({
  app,
  dsn: 'https://d15e3d23ae4d3a51e634a91a57d39906@o1176404.ingest.us.sentry.io/4508237446381568',
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 0.05, //  Capture 5% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: appEnv.appEnv,
  ignoreErrors: ['GeolocationPositionError'],
});

import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

app.use(router);

import { vMaska } from 'maska/vue';
app.directive('maska', vMaska);

import { createHead } from '@unhead/vue';
const head = createHead();
app.use(head);

import VueGtag from 'vue-gtag';
app.use(
  VueGtag,
  {
    bootstrap: false,
  },
  router
);

app.mount('#app');
