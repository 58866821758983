<script setup lang="ts">
import { useActivity } from '@/composables/useActivity';
import BannerLayout from '@/layouts/BannerLayout.vue';
import EntryForm from '@/components/entryForm/EntryForm.vue';
import { useEntryStore } from '@/stores/entry';
import type { RawEntry } from '@/types/RawEntry';
import router from '@/router';
import { ref } from 'vue';
import { toast } from '@/helpers/toast';
import { HTTPError } from 'ky';
import { useRawEntry } from '@/composables/useRawEntry';

const activity = useActivity();
const entryStore = useEntryStore();

const loading = ref(false);
const formEntry = useRawEntry();
const form = activity.frontend.form;
form.buildRules();

async function submitForm(data: RawEntry) {
  try {
    await entryStore.submitEntry(data);
  } catch (error) {
    console.error(error);
    loading.value = false;

    if (!(error instanceof HTTPError) || error.response.status >= 500) {
      toast.error('An unknown error occurred. Please try again soon or contact us.');
      return;
    }

    const responseJson = await error.response.json();

    toast.error(responseJson.message, 'Submission Error');
    return;
  }
  console.info('Entry submitted');

  if (entryStore.entry?.testAlerts)
    entryStore.entry.testAlerts.forEach((alert) => toast.warning(alert, '(Test Mode) Bypassed Errors'));

  if (activity.frontend.pages.game.enabled) return await router.push({ path: '/game' });

  await router.push({ path: '/result' });
}
</script>

<template>
  <BannerLayout show-age-gate>
    <EntryForm
      v-model="formEntry"
      :form="form"
      :disabled="loading"
      @submit="submitForm"
      @start-submit="loading = true"
      @error="loading = false"
    />
  </BannerLayout>
</template>

<style scoped></style>
